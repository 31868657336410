import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>#404 NOT FOUND</h1>
    <img src="https://media.giphy.com/media/hRhCc5bbYh9w4/giphy.gif" />
    <p>Help is on its way. In the meantime you can go <a href="/">back home</a>.</p>
  </Layout>
)

export default NotFoundPage
